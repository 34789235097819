import './ShopCart.scss'
import React, { Component } from 'react'
import InsHeader from '../../../../components/InsHeader/InsHeader'
import InsLoader from '../../../../components/InsLoader/InsLoader'
import InsNavigation from '../../../../components/InsNavigation/InsNavigation'
import CartHeader from '../../../../components/Shop/Cart/CartHeader'
import CartItem from '../../../../components/Shop/Cart/CartItem'
import { getCart, addProductToCart, removeProductToCart, sendUserOrder } from '../../../../api/shop'
import { withTranslation } from 'react-i18next'
import { refreshAccountData } from '../../../../api/account'
import EditAddress from '../../../../components/Shop/Cart/EditAddress'
import InsButton from '../../../../components/InsButton/InsButton'
import images from '../../../../ressources/images'
import { connect } from 'react-redux'

export class ShopCart extends Component {
  constructor(props) {
    super(props)

    this.state = {
      account: {},
      cart: {},
      contentHeight: window.innerHeight,
      loader: true,
      editAddress: false,
      editedAddress: '',
      editedAddress2: '',
      editedPostal_code: '',
      editedCity: '',
      editedPhone: '',
      processing: false,
      orderError: false,
      orderErrorText: '',
    }
  }
  componentDidMount() {
    const { account, history } = this.props
    if (!account.isForwork) {
      history.replace('/')
    }
    this.getAccountDatas()
    this.getUserCart()
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ contentHeight: window.innerHeight })
  }

  getAccountDatas = () => {
    const { account } = this.props
    let accountTemp = { ...account }
    delete accountTemp.lastUpdated
    this.setState({ account: accountTemp })
  }

  getUserCart = () => {
    getCart().then(data => {
      this.setState({
        cart: data,
        loader: false
      })
    })
  }

  addOneItem = (ean) => {
    let obj = {}
    obj.quantity = 1
    addProductToCart(ean, obj).then(data => {
      this.setState({ cart: data })
    })
  }

  removeOneItem = (ean) => {
    let obj = {}
    obj.quantity = 1
    removeProductToCart(ean, obj).then(data => {
      this.setState({ cart: data })
    })
  }

  removeProduct = (ean) => {
    removeProductToCart(ean, {}).then(data => {
      this.setState({ cart: data })
    })
  }

  editAddress = () => {
    this.refs['cart_page'].scrollTo(0, 0)
    this.setState({ editAddress: !this.state.editAddress })
  }

  handleChange = (params) => (event) => {
    const value = event.target.value
    this.setState({
      [params]: value
    })
  }

  saveAddress = () => {
    const { account, editedAddress, editedAddress2, editedPostal_code, editedCity, editedPhone } = this.state
    this.setState({
      account: {
        ...account,
        address: editedAddress,
        address2: editedAddress2,
        postal_code: editedPostal_code,
        city: editedCity,
        phone: editedPhone
      }
    }, () => {
      this.editAddress()
    })
  }

  sendOrder = () => {
    const { account } = this.state
    const { history } = this.props

    this.setState({
      processing: true,
      orderError: false
    })

    let obj = {}
    obj.address = account.address
    obj.address2 = account.address2
    obj.city = account.city
    obj.postal_code = account.postal_code
    obj.phone = account.phone
    sendUserOrder(obj).then(data => {
      refreshAccountData()
      history.replace('/shop/cart/order=' + data.number)
      this.setState({
        cart: {
          count: 0,
          elements: [],
          total: 0
        }
      })
    }).catch(error => {
      this.setState({
        processing: false,
        orderError: true,
        orderErrorText: error
      })
    });
  }

  cartValidation = () => {
    const { account, cart } = this.state
    const { address, postal_code, city, phone, ordersCount, hasOrderLessOneHour } = account
    console.log(postal_code);
    let valid = address !== null && postal_code !== null && city !== null && phone !== null && !this.isPhoneInvalid(phone) && cart.count > 0 && ordersCount < 2 && !this.isPostalCodeInvalid(postal_code) && !this.isAddressLengthInvalid(account.address) && !this.isAddressLengthInvalid(account.address2) && !hasOrderLessOneHour
    return !valid
  }

  isPostalCodeInvalid(postalCode) {
    const regexDOMTOM = /^(?!97[0-9]{3}|98[0-9]{3}|99[0-9]{3})(0[1-9]|[1-8][0-9]|9[0-8])\d{3}$/; // France Regex - DOM TOM excluded
    return !regexDOMTOM.test(postalCode);
  }
  
  isPhoneInvalid(phone) {
    const regexPhone = /^(06|07)\d{8}$/; // Phone regex
    return !regexPhone.test(phone);
  }

  isAddressLengthInvalid(address) {
    if(address !== undefined && address !== null){
      return address.length > 32;
    }
    return false;
  }
  
  render() {
    const { account, cart, contentHeight, editAddress, editedAddress, editedPostal_code, editedCity, editedPhone, loader, processing, orderError, orderErrorText } = this.state
    const { t } = this.props
    let contentStyle = { height: contentHeight - 120 }
    let limitOrderMessage = "";
    if (account.ordersCount >= 2)
      limitOrderMessage = "Vous avez atteint la limite de commandes de l'année";
    else if (account.ordersCount == 1)
      limitOrderMessage = "Il ne vous reste plus qu'une commande pour l'année";
    return (
      <>
        <InsHeader />
        <div className="cart_page" ref="cart_page" style={contentStyle}>
          <CartHeader />

          {loader ?
            <InsLoader />
            :
            <>

              <div className="top_content">
                <div className="item_container">
                  {cart.elements
                    ? cart.elements.map((item, index) => (
                      <CartItem
                        key={index}
                        picture={item.pictures.length ? item.pictures[0].name.name_333 : images.we_are_lol_black}
                        brand={item.brand}
                        name={item.name}
                        points={item.points}
                        quantity={item.quantity}
                        totalCart={cart.total}
                        userPoints={account.points}
                        addOne={() => this.addOneItem(item.ean)}
                        removeOne={() => this.removeOneItem(item.ean)}
                        removeProduct={() => this.removeProduct(item.ean)}
                      />
                    ))
                    :
                    <div className="empty_cart">
                      {t('shop:empty_cart')}
                    </div>
                  }
                </div>
              </div>

              <div className="bottom_content">
                <div className="cart_total">TOTAL : {cart.total} points</div>

                <div className="delivery_address">
                  <div className="title">{t('shop:my_delivery_address')}</div>
                  <div className="delivery_address_container">
                    <div className="delivery_infos">
                      <div className="name">{account.firstname} {account.lastname}</div>
                      <div className="address">
                        {account.address ? account.address + ' - ' + account.postal_code + ' ' + account.city : t('shop:add_delivery_address')}
                        <div className="additional">
                          {account.address2 ? account.address2 : ''}
                        </div>
                        <div className="additional">
                          {account.phone ? account.phone : ''}
                        </div>
                      </div>
                    </div>
                    <div className="edit_button" onClick={this.editAddress}></div>
                  </div>
                </div>

                <div className="advertisement">
                  {t('shop:advertisement')}
                </div>

                {orderError &&
                  <div className="order_error">
                    {orderErrorText}
                  </div>
                }

                <InsButton
                  text={processing ? t('shop:processing') : t('shop:command')}
                  onClick={this.sendOrder}
                  disabled={this.cartValidation()}
                />

                {account.phone === undefined || account.phone === null &&
                    <div className="phone_order_message">
                      {t('shop:phone_order_message')}
                    </div>
                }
          
                {account.phone && this.isPhoneInvalid(account.phone) &&
                    <div className="phone_order_message">
                      {t('shop:phone_format_order_message')}
                    </div>
                }
                
                {this.isPostalCodeInvalid(account.postal_code) &&
                    <div className="postal_code_order_message">
                      {t('shop:postal_code_order_message')}
                    </div>
                }

                {account.hasOrderLessOneHour &&
                    <div className="limit_one_hour_order_message">
                      {t('shop:limit_order_message_less_one_hour')}
                    </div>
                }
                
                {limitOrderMessage != "" &&
                  <div className="limit_order_message">
                    {limitOrderMessage}
                  </div>
                }

                {this.isAddressLengthInvalid(account.address) &&
                    <div className="postal_code_order_message">
                      {t('shop:length_address_message')}
                    </div>
                }

                {this.isAddressLengthInvalid(account.address2) &&
                    <div className="postal_code_order_message">
                      {t('shop:length_address2_message')}
                    </div>
                }
              </div>

              {/* POP IN EDIT ADDRESS */}
              {editAddress &&
                <EditAddress
                  close={this.editAddress}
                  handleChange={this.handleChange}
                  saveAddress={this.saveAddress}
                  editedAddress={editedAddress}
                  editedPostal_code={editedPostal_code}
                  editedCity={editedCity}
                  editedPhone={editedPhone}
                  account={account}
                />
              }
            </>
          }


        </div>
        <InsNavigation />
      </>
    )
  }
}


//extract data from the redux store
const mapStateToProps = (state) => ({
  account: state.account
})

export default (connect(mapStateToProps)(withTranslation()(ShopCart)))
