import React, {useState, useEffect} from 'react'
import { withTranslation } from 'react-i18next'
import InsInput from '../../../components/InsInput/InsInput'
import InsButton from '../../../components/InsButton/InsButton'

const EditAddress = (props) => {
  const [contentHeight, setContentHeight] = useState(window.innerHeight)
  const {close, editedAddress, editedPostal_code, editedCity, editedPhone, handleChange, saveAddress, account, t} = props

  const updateDimensions = () => {
    setContentHeight(window.innerHeight)
  }

const canSubmit = () => {
  if(!account.adress && !editedAddress) {
    return false;
  }

  if(!account.postal_code && !editedPostal_code) {
    return false;
  }

  if(!account.city && !editedCity) {
    return false;
  }

  if(!account.phone && !editedPhone) {
    return false;
  }

  return true;
}
  
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
  }, [contentHeight])

  let contentStyle = {height: contentHeight}

  return (
    <div className="modal_edit_address" style={contentStyle}>
      <div className="close" onClick={close}></div>
      <div className="modal_header">
        <div className="title">
          {t('shop:my_delivery_address')}
        </div>
      </div>
      <div className="modal_content">
        <InsInput    
          name="address"          
          type="text"
          placeholder={t("account:address")}
          onChange={handleChange('editedAddress')}
          maxLength="115"
          id='editAddress'
          defaultValue={account.address}
        />
        <InsInput    
          name="address2"          
          type="text"
          placeholder={t("account:address2")}
          onChange={handleChange('editedAddress2')}
          maxLength="115"
          id='editAddress2'
          defaultValue={account.address2}
        />
        <InsInput    
          name="postal_code"
          type = "number"
          onInput={(e)=> {e.target.value = e.target.value.slice(0,5)}}
          maxLength="5"
          placeholder={t("account:postal_code")}
          onChange={handleChange('editedPostal_code')}
          id='editPostal_code'
          defaultValue={account.postal_code}
        />
        <InsInput    
          name="city"          
          type="text"
          placeholder={t("account:city")}
          onChange={handleChange('editedCity')}
          id='editCity'
          defaultValue={account.city}
        />
        <InsInput
            name="phone"
            type="text"
            placeholder={t("account:phone")}
            onChange={handleChange('editedPhone')}
            id='editPhone'
            defaultValue={account.phone}
        />
        <InsButton
          text={t('common:save')}
          onClick={saveAddress}
          disabled={!canSubmit()}
        />
        <p className="cancel" onClick={close}>{t('common:cancel')}</p>
      </div>
    </div>
  )
}

export default withTranslation()(EditAddress)
