import './Scorm.scss'
import React, { Component } from 'react'
import images from '../../../ressources/images';
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/scormActions';
import { withTranslation } from 'react-i18next'

class Scorm extends Component {
  // src/server/public/uploads/scorm/formation/index.html
  constructor(props){
    super(props)

    window.API_1484_11 = this;
  }

  componentDidUpdate() {
    this.scormEnd()
  }

  Initialize(){
    return true
  }

  Terminate(){
    return true
  }

  Finish (){
    return true
  }

  LMSCommit  (){
    return true
  }
  Commit  (){
    return true
  }

  GetValue(CMIElement){
    const id = this.props.idScorm

    if(this.props.scorm.scormValue[id]) {
      return this.props.scorm.scormValue[id][CMIElement] || ''
    } else {
      return ''
    }
  }

  SetValue(CMIElement, value){
    const id = this.props.idScorm
    this.props.setScormValue(CMIElement, value, id)
    return true
  }

  GetLastError(){
    return 0
  }

  GetErrorString(CMIErrorCode){
    return null
  }

  GetDiagnostic(CMIErrorCode){
    return null
  }

   /**
   * Quitte le module et revient à la page index de la formation
   */
  closeQuizz = () => {
    this.props.close()
  }

  scormEnd = () => {
    const id = this.props.idScorm

    let isCompleted = this.props.scorm.scormValue[id]['cmi.completion_status'] === "completed"
    let isPassed = this.props.scorm.scormValue[id]['cmi.success_status'] === "passed"

    if(isCompleted && isPassed) {
      const path = window.location.pathname      
      setTimeout(() => {
        window.location.replace(path+'/result');
        this.props.refreshScormValue(id)
      }, 5000);
    }
  }

  render() {
    const {scorm_path, t} = this.props
    return (
      <div className="scorm_wrapper">        
        <div className="close_quizz" onClick={this.closeQuizz}>
          <img src={images.closeCross} alt=""/>
        </div> 
        <object data={process.env.REACT_APP_URL + scorm_path} width="100%" height="100%">
          Error: learning could not be displayed.
          {t('error:learning.default')}
        </object>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  scorm: state.scorm
})

const mapDispatchToProps  = (dispatch) => {
  return {
    setScormValue: (key, value, id) => { dispatch(actions.setScormValue(key, value, id)) },
    refreshScormValue: (key, value, id) => { dispatch(actions.refreshScormValue(key, value, id)) },
	}
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Scorm))
