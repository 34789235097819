import "./Shop.scss"
import React, { Component } from "react"
import { Link } from "react-router-dom"
import images from "../../../ressources/images"
import InsHeader from "../../../components/InsHeader/InsHeader"
import InsNavigation from "../../../components/InsNavigation/InsNavigation"
import ShopHeader from "../../../components/Shop/List/ShopHeader"
import ShopListItem from "../../../components/Shop/List/ShopListItem"
import InsCGUBar from "../../../components/InsCGUBar/InsCGUBar"
import InsLoader from "../../../components/InsLoader/InsLoader"
import ShopFilter from "../../../components/Shop/List/ShopFilter"
import { getProducts, getCart, getCategories } from "../../../api/shop"
import InfiniteScroll from "react-infinite-scroll-component"
import { connect } from "react-redux"
import { getAllPosts } from "../../../api/wall"

export class Shop extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cart: {},
      open: true,
      products: [],
      openFilter: false,
      contentHeight: window.innerHeight,
      loader: true,
      categories: {},
      categoryImg: "",
      loaderFilter: true,
      offset: 0
    }
  }

  componentDidMount() {
    this.getAccountDatas()
    this.getAllProducts()
    this.getAllCategories()
    this.getUserCart()
    window.addEventListener("resize", this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions)
  }

  updateDimensions = () => {
    this.setState({ contentHeight: window.innerHeight })
  }

  getAccountDatas = () => {}

  getAllProducts = () => {
    getProducts(this.state.offset).then(
      (data) => {
        this.setState({
          products: [...this.state.products, ...data],
          loader: false,
          offset: this.state.offset + 1
        })
      },
      () => {
        if (this.state.offset === 0) this.setState({ open: false })
      }
    )
  }

  getAllCategories = () => {
    getCategories().then((data) => {
      this.setState({
        categories: data,
        categoryImg: data.defaultBanner.name.name,
        loaderFilter: false
      })
    })
  }

  updatedProducts = (data) => {
    this.setState({
      products: data.gifts,
      categoryImg:
        data.category && data.category.picture
          ? data.category.picture.name.name
          : data.categories.defaultBanner
          ? data.categories.defaultBanner.name.name
          : this.state.categoryImg,
      openFilter: false
    })
  }

  getUserCart = () => {
    getCart().then((data) => {
      this.setState({
        cart: data
      })
    })
  }

  toggleFilter = () => {
    this.setState({ openFilter: !this.state.openFilter })
  }

  render() {
    const { cart, categories, categoryImg, contentHeight, loader, loaderFilter, open, openFilter, products } =
      this.state
    const { account } = this.props
    let contentStyle = { height: contentHeight - 170 }
    if (account.isValidSector) contentStyle = { height: contentHeight - 170, opacity: 0.9 }

    return (
      <>
        <InsHeader />
        {open ? (
          <>
            <ShopHeader account={account} cart={cart} filter={this.toggleFilter} />
            <ShopFilter
              className={openFilter ? "shop_filter open" : "shop_filter"}
              filter={this.toggleFilter}
              updatedProducts={this.updatedProducts}
              categories={categories}
              loader={loaderFilter}
            />
            <div className="product_list" style={contentStyle} id="test">
              <div className="product_list-banner">
                <img src={categories.defaultBanner && process.env.REACT_APP_URL + categoryImg} alt="" />
              </div>
              <div className="items_container">
                <div className="item_list">
                  {loader ? (
                    <InsLoader />
                  ) : (
                    <InfiniteScroll
                      dataLength={products.length}
                      next={this.getAllProducts}
                      hasMore={true}
                      scrollableTarget="test"
                      loader={loader}
                      style={{
                        overflow: "hidden"
                      }}
                    >
                      {products.map((product, index) => (
                        <Link
                          key={index}
                          className={product.star ? "product_wrapper big" : "product_wrapper"}
                          to={{
                            pathname: "/shop/product/" + product.id,
                            state: { transitionName: "slide_in" }
                          }}
                        >
                          <ShopListItem
                            variations={product.variations}
                            name={product.name}
                            brand={product.brand.name}
                            image={
                              product.pictures.length ? product.pictures[0].name.name_333 : images.we_are_lol_black
                            }
                            points={product.points}
                            stock={product.stock}
                            star={product.star}
                            outlet={product.outlet}
                            limited={product.limited}
                            userPoints={account.points}
                            gold={product.gold}
                            cart={cart}
                          />
                        </Link>
                      ))}
                    </InfiniteScroll>
                  )}
                </div>
              </div>
              <InsCGUBar />
            </div>
          </>
        ) : !open &&
          account.shopState.picture !== null ? (
          <div>
            <div className="popup-prestataire popup-prestataire__title">{account.shopState.title}</div>
            <div className="popup-prestataire popup-prestataire__text">{account.shopState.content}</div>
            <img
              src={account.shopState.picture}
              className="shop_waiting"
              alt="encore un peu de patience, vous pourrez bientôt faire échanger vos points contre vos produits préférés!"
            />
          </div>
        ) : (
          <div>
            <img
              src={images.shop_waiting}
              className="shop_waiting coucou"
              alt="encore un peu de patience, vous pourrez bientôt faire échanger vos points contre vos produits préférés!"
            />
          </div>
        )}
        <InsNavigation />
      </>
    )
  }
}

//extract data from the redux store
const mapStateToProps = (state) => ({
  account: state.account
})

export default connect(mapStateToProps)(Shop)
